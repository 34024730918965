<template>
  <div
    class="selectaria"
    :ref="`selectaria_${uId}`"
    :class="[
      { selectaria_error: isError, selectaria_success: !isError && selectVal },
    ]"
  >
    <div class="selectaria__label" v-if="label" v-html="label"></div>
    <v-select
      :searchable="searchable"
      v-model="selectVal"
      class="selectaria__select"
      :options="items"
      label="label"
      :clearable="false"
      :deselectFromDropdown="multiple"
      :closeOnSelect="!multiple"
      :reduce="(item) => item.value"
      :multiple="multiple"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="change"
      @open="fixOpenScroll"
      @option:selected="optionTriggered($event, true)"
      @option:deselecting="optionTriggered($event, false)"
    >
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">
          {{ searchEmpty }}
        </template>
        <template v-else> Список пуст </template>
      </template>
      <template
        v-if="selectVal && selectVal.length && !disabled"
        #selected-option-container="{ option }"
      >
        <span class="vs__selected"
          >{{ option.label
          }}{{ (selectVal && selectVal.length) > 1 ? ", " : "" }}</span
        >
      </template>
      <template
        v-else-if="selectVal && selectVal.length && disabled"
        #selected-option-container="{}"
      >
        <span>{{ placeholder }}</span>
      </template>
      <template
        v-else-if="!selectVal || !selectVal.length"
        #selected-option-container="{}"
      >
        <span>{{ placeholder }}</span>
      </template>
      <template #search="{ attributes, events }">
        <div v-if="!selectVal || !selectVal.length">{{ placeholder }}</div>
        <input
          maxlength="1"
          class="vs__search"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <!-- <template v-else #selected-option-container="">
        <div>{{ placeholder }}</div>
      </template> -->
      <template #list-header>
        <slot name="list-header"></slot>
      </template>

      <template v-if="!disabled" #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_172_2288)">
              <path
                d="M10.7195 2.8447L5.9998 7.56436L1.28012 2.8447C1.21063 2.77436 1.12792 2.71846 1.03675 2.6802C0.945571 2.64195 0.847733 2.6221 0.748859 2.6218C0.649985 2.6215 0.552027 2.64076 0.460621 2.67845C0.369216 2.71615 0.286166 2.77155 0.216252 2.84147C0.146337 2.91138 0.0909369 2.99443 0.0532382 3.08584C0.0155385 3.17724 -0.00371429 3.2752 -0.00341389 3.37408C-0.00311444 3.47295 0.0167334 3.57079 0.0549862 3.66196C0.09324 3.75314 0.149143 3.83585 0.219481 3.90534L5.46948 9.15534C5.61013 9.29599 5.80089 9.375 5.9998 9.375C6.19871 9.375 6.38947 9.29599 6.53012 9.15534L11.7801 3.90534C11.9194 3.7644 11.9972 3.57408 11.9966 3.37596C11.996 3.17784 11.917 2.988 11.7769 2.84791C11.6368 2.70781 11.447 2.62884 11.2489 2.62824C11.0507 2.62764 10.8604 2.70546 10.7195 2.8447Z"
                fill="#3C4242"
              />
            </g>
            <defs>
              <clipPath id="clip0_172_2288">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 12 12)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
      </template>
    </v-select>
    <div class="selectaria__error" v-if="isError" v-html="errorText"></div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    searchEmpty: { type: String, default: "Ничего не найдено" },
    value: Array,
    placeholder: String,
    items: Array,
    label: String,
    multiple: Boolean,
    disabled: Boolean,
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorText: "",
    isError: false,
  }),
  computed: {
    uId() {
      return this._uid;
    },
    selectVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.multiple) {
          this.$emit("input", val);
        } else {
          this.$emit("input", [val]);
        }
      },
    },
  },
  methods: {
    change() {
      this.$emit("change");
    },
    optionTriggered(arg, state) {
      this.$emit("optionTriggered", arg, state);
    },
    fixOpenScroll() {
      const select = this.$refs[`selectaria_${this.uId}`];
      select.querySelector(".vs__selected-options").scrollLeft = 0;
      select.querySelector(".vs__selected-options").scrollTop = 0;
    },
  },
};
</script>


<style lang="scss">
.icc {
  .selectaria {
    width: 100%;
    .vs__search {
      position: absolute !important;
    }
    .vs__dropdown-menu {
      padding: 16px !important;
      top: calc(100% + 10px) !important;
      background: #ffffff;
      border-radius: 8px;

      @media screen and (max-width: 767px) {
        max-height: 320px;
      }
    }
    &__label {
      margin-bottom: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      @media screen and (max-width: 767px) {
        margin-bottom: 12px;
      }
    }

    &__select {
      padding: 0 16px;
      width: 100%;
      height: 40px;
      // max-width: 527px;
      background: #f8f8f8;
      border: 1px solid #d2d2d2;
      border-radius: 99px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;

      color: #fff;
      display: block;
      transition: 0.3s;
      &:hover {
        border-color: #830051;
      }
      &.vs--open {
        border-color: #830051;
      }
      .vs__dropdown-toggle {
        height: 100%;
        border: none;
        padding: 0;
        align-items: center;
        .vs__search::placeholder {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: #b2b4b4;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .vs__clear {
          display: none;
        }

        .vs__selected {
          margin: 0;
          position: static;
          opacity: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }

        .vs__selected > * {
          min-width: 0;
        }
      }

      .vs__dropdown-option {
        white-space: inherit;
        padding: 3px 0px !important;
        color: #8b8e8e !important;
      }

      .vs__selected-options {
        // width: 100%;
        // min-width: 0;
        display: block;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #b2b4b4;
        overflow: hidden;

        & span {
          display: block;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #3c4242;
        }
      }
    }
    &__error {
      margin-top: 8px;
      font-family: "Murecho", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #cb4050;
    }
    &_error {
      & .selectaria__select {
        border-color: #cb4050;
        background-color: #faebed;
      }
    }
  }

  .placeholder-immitation {
    // display: none;
    &:first-child {
      display: flex;
      align-items: center;
      line-height: 1.4;
      font-size: 1em;
      font-family: "Murecho", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .vs__dropdown-option--selected {
    color: #830051 !important;
  }

  .vs__dropdown-option--deselect {
    background: none;
    color: inherit;
  }

  .vs__dropdown-option--highlight {
    background: none;
    color: inherit;
  }

  .vs__dropdown-option:hover {
    // background: #830051;
    color: #830051 !important;
  }
}
</style>
<style lang="scss">
.icc {
  .vs__dropdown-option:hover .checkbox__label {
    color: #830051 !important;
  }

  .vs--disabled .vs__dropdown-toggle,
  .vs--disabled .vs__clear,
  .vs--disabled .vs__search,
  .vs--disabled .vs__selected {
    background-color: #f8f8f8;
  }

  .vs--disabled {
    pointer-events: none;
  }

  .vs--disabled .vs__selected-options {
    color: #3c4242;
  }

  .vs--disabled .vs__open-indicator {
    display: none;
  }

  .vs--disabled .vs__search {
    display: none;
  }
}
</style>